<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-8">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Role and Permission</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >Manage Role and Permission</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Roles and Permissions</h4>
          </div>
        </div>
      </div>
      <div class="col-4 tx-right" v-if="checkSinglePermission('create_role')">
        <!-- <button
          type="button"
          class="btn btn-sm btn-primary mg-t-10 mg-r-0 mg-b-10"
          @click="$store.dispatch('modalOpen','addNewRole')"
        >New Role</button>-->
        <button class="btn btn-sm btn-primary mg-t-10 mg-r-0 mg-b-10" @click="newRole()">New Role</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-9 d-block mr-0 pr-0">
          <select v-model="filterParams.pageIndex" @change="selectTotalData" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
        </div>
        <div class="col-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="filterParams.searched"
            class="float-right"
            placeholder="Search..."
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th scope="col" class="text-center">S.N.</th>
              <th scope="col" class="text-center">Role Name</th>
              <th scope="col" class="text-center">Priority</th>
              <th scope="col" class="text-center">Status</th>
              <th
                scope="col"
                class="text-center"
                v-if="checkIfAnyPermission(['edit_role','delete_role'])"
              >Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && roles.length > 0">
            <tr v-for="(role,index) in roles" :key="index">
              <th scope="row" class="text-center">{{++index}}</th>
              <td class="text-center">{{role.name}}</td>
              <td class="text-center">{{role.priority}}</td>
              <td class="text-center">
                <span v-if="role.status ==1">Active</span>
                <span v-if="role.status ==0">Passive</span>
              </td>
              
              <td 
                class="text-center table-end-item no-print"
                v-if="checkIfAnyPermission(['edit_role','delete_role'])"
              >
                <a
                  href="javascript:;"
                  class="mr-3"
                  title="Edit Role"
                  @click="editRole(role.id)"
                  v-if="checkSinglePermission('edit_role') && role.priority != 0 && role.priority > user_priority "
                >
                  <i class="fa fa-edit"></i>
                </a>
                <!-- <a href="javascript:;" class="mr-3" title="Delete Order" @click="drop(order.id)">
                  <i class="fa fa-trash"></i>
                </a>-->
                <a
                  href="javascript:;"
                  @click="deleteRole(role.id)"
                  v-if="checkSinglePermission('delete_role') && role.priority != 0 && role.priority > user_priority"
                >
                  <i class="fa fa-ban tx-danger" title="Delete Role"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align:center;">{{ errorMessage }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && roles.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import Services from "./Services/Service";
import _ from "lodash";

export default {
  components: {
    Paginate,
  },
  data() {
    return {
      roles: [],
      orderStatus: 1,
      page: 1,
      loading: true,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      error: false,
      errorMessage: "",
      filterParams: {
        pageIndex: 10,
        searched: "",
        offset: 0,
      },
      user_priority:'' ,
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },
  beforeMount() {
    this.getFilteredData();
    this.pageReload = true;
  },
  methods: {
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = page * this.filterParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredData();
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredData();
    },
    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;

      this.getFilteredData();
    }, 500),

    //today/month section
    getFilteredData() {
      this.loading = true;
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.error = false;
      Services.getFilteredRoles(params)
        .then((response) => {
          let value = response.data.data;
          this.roles = value.roles;
          this.user_priority = response.data.data.user_priority;
          this.totalPageCount = value.total_no_of_pages;
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newRole() {
      this.$router.push({ name: "new-role" });
    },
    editRole(id) {
      this.$router.push({ name: "edit-role", params: { id: id } });
    },
    deleteRole(id) {
      this.$swal({
        title: "Do you really want to delete this role ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // cancel the sales with remarks
          return Services.deleteRole(id)
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.roles.forEach((role, index) => {
                  if (role.id == response.data.data) {
                    this.roles.splice(index, 1);
                  }
                });
                this.setNotification(success);
              }
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
  },

  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>